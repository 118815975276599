/**
 * This acts as an intermediary service for Nav, maintaining the component's navigation items
 * between mounts and handling changes due to nested dependencies.
 * If your MFE does not interact with Nav you can remove everything from this file.
 */
import {Nav, NavItem} from '@verily-src/nav';
import {BehaviorSubject} from 'rxjs';

const items = new BehaviorSubject<NavItem[]>([]);

// Setup handlers for component to interact with Nav
export const addItemToNav = (route: NavItem) => {
  Nav.addItem(route);

  const oldNavItems = items.value;
  items.next([...oldNavItems, route]);
};

/**
 * Removes a route(NavItem) and updates the navItem list
 */
export const removeItemFromNav = (route: NavItem) => {
  // Remove the item from the Nav
  Nav.removeItem(route);

  // Update the list of controlled items so we can use it for our internal state
  const controlledItems = items.value.filter(item => item.name !== route.name);
  items.next(controlledItems);
};

// Setup initial routes
const testNavItem = {
  name: 'phr-vaccinations-mfe - Item',
  path: '/phr-vaccinations-mfe',
  order: 0,
  icon: 'Group',
};

/**
 * Use this function to wrap any initialization logic when setting up the initial nav items to register and show.
 */
function initRoutes() {
  const initialRoutes = [testNavItem];
  items.next(initialRoutes);
  initialRoutes.forEach(route => addItemToNav(route));
}

// Run initialization function once during import - https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Statements/import#import_a_module_for_its_side_effects_only
initRoutes();
